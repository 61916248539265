import { createContext, useState } from 'react'

export type UnsavedChangesContextType = {
  /**
   * Object [key]:boolean[] where the key corresponds to the stepper name and the array is every step we want to validate
   */
  unsavedChanges: {
    field: boolean[]
    preset: boolean[]
  }
  setUnsavedChanges: React.Dispatch<
    React.SetStateAction<{
      field: boolean[]
      preset: boolean[]
    }>
  >
  validateUnsavedChanges: { open: boolean; callback: () => void }
  setValidateUnsavedChanges: React.Dispatch<
    React.SetStateAction<{ open: boolean; callback?: () => void }>
  >
  resetUnsavedChanges: () => void
}

interface UnsavedChangesContextProviderProps {
  children: React.ReactNode
}
const initialValues = {
  unsavedChanges: {
    field: [false, false, false],
    preset: [false, false, false, false, false],
  },
  setUnsavedChanges: () => ({}),
  validateUnsavedChanges: { open: false, callback: () => ({}) },
  setValidateUnsavedChanges: () => ({}),
  resetUnsavedChanges: () => ({}),
}
export const UnsavedChangesContext = createContext<UnsavedChangesContextType>({
  ...initialValues,
})

export function UnsavedChangesContextProvider({ children }: UnsavedChangesContextProviderProps) {
  const [unsavedChanges, setUnsavedChanges] = useState(initialValues.unsavedChanges)
  const [validateUnsavedChanges, setValidateUnsavedChanges] = useState({
    open: false,
    callback: () => ({}),
  })
  const resetUnsavedChanges = () => {
    setUnsavedChanges(initialValues.unsavedChanges)
  }
  return (
    <UnsavedChangesContext.Provider
      value={{
        unsavedChanges,
        setUnsavedChanges,
        validateUnsavedChanges,
        setValidateUnsavedChanges,
        resetUnsavedChanges,
      }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  )
}
