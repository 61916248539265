import { Button } from 'antd/lib'
import { useUnsavedChanges } from 'hooks/useUnsavedChanges'
import useTranslation from 'next-translate/useTranslation'
import { IconType, NotificationModal } from '../NotificationModal'

export const UnsavedChangesModal = () => {
  const { t: tCommon } = useTranslation('common')
  const { validateUnsavedChanges, setValidateUnsavedChanges } = useUnsavedChanges()
  return (
    <NotificationModal
      open={validateUnsavedChanges.open}
      titleIconType={IconType.INFO}
      closable
      title={tCommon('modal.go_back.header')}
      body={tCommon('modal.go_back.explanation')}
      width={438}
      centered
      onCancel={() => setValidateUnsavedChanges({ open: false })}
      footer={null}
      footerBtns={[
        <Button
          key={0}
          type="ghost"
          aria-label="Cancel go back"
          onClick={() => setValidateUnsavedChanges({ open: false })}
        >
          {tCommon('modal.go_back.cancel')}
        </Button>,
        <Button
          key={1}
          type="ghost"
          danger
          aria-label="Leave preset"
          onClick={() => {
            setValidateUnsavedChanges({ open: false })
            validateUnsavedChanges.callback()
          }}
        >
          {tCommon('modal.go_back.confirm')}
        </Button>,
      ]}
    />
  )
}
