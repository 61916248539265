import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { UserDTO } from '../api/api_code'

export type AuthContextType = {
  user: UserDTO | null
  setUser: Dispatch<SetStateAction<UserDTO>>
}

interface AuthContextProviderProps {
  children: React.ReactNode
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => ({}),
})

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [user, setUser] = useState<UserDTO | null>(null)

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
}
