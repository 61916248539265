import { UnsavedChangesContext } from 'context/UnsavedChangesContext'
import { useContext, useEffect } from 'react'

export function useUnsavedChanges() {
  const {
    unsavedChanges,
    setUnsavedChanges,
    validateUnsavedChanges,
    setValidateUnsavedChanges,
    resetUnsavedChanges,
  } = useContext(UnsavedChangesContext)
  return {
    unsavedChanges,
    setUnsavedChanges,
    validateUnsavedChanges,
    setValidateUnsavedChanges,
    resetUnsavedChanges,
  }
}

export type CompObject<T> = {
  [K in keyof T]: unknown
}

type UnsavedChangesArgs = {
  step: {
    key: 'preset' | 'field'
    value: number
  }
  compLocal: CompObject<Record<string, unknown>>
  compOrigin: CompObject<Record<string, unknown>>
  compKeys: CompObject<Record<string, unknown>>
}
export const useUnsavedChangesHandler = ({
  step,
  compLocal,
  compOrigin,
  compKeys,
}: UnsavedChangesArgs) => {
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges()
  const modifiedValues = [...unsavedChanges[step.key]]
  const prevUnsavedChanges = [...unsavedChanges[step.key]]

  useEffect(() => {
    Object.keys(compKeys).every((key) => {
      const isObjectLocal =
        compLocal?.[key] instanceof Object &&
        compLocal?.[key] !== null &&
        !(compLocal?.[key] instanceof Array)
      const isObjectOrigin =
        compOrigin?.[key] instanceof Object &&
        compOrigin?.[key] !== null &&
        !(compOrigin?.[key] instanceof Array)

      const compLocalSorted = isObjectLocal
        ? JSON.stringify(compLocal?.[key], Object.keys(compLocal?.[key]).sort())
        : JSON.stringify(compLocal?.[key])
      const compOriginSorted = isObjectOrigin
        ? JSON.stringify(compOrigin?.[key], Object.keys(compOrigin?.[key]).sort())
        : JSON.stringify(compOrigin?.[key])

      if (compOriginSorted !== compLocalSorted) {
        modifiedValues[step.value - 1] = true
        return false
      }
      modifiedValues[step.value - 1] = false
      return true
    })
    if (JSON.stringify(prevUnsavedChanges) !== JSON.stringify(modifiedValues))
      setUnsavedChanges({ ...unsavedChanges, [step.key]: modifiedValues })
  }, [compLocal])
}
