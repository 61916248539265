import ADD from 'assets/icons/Add.svg'
import ALERT from 'assets/icons/Alert.svg'
import AMBIENT from 'assets/icons/Ambient.svg'
import ARROW_LEFT from 'assets/icons/ArrowLeft.svg'
import ARROW_RIGHT from 'assets/icons/ArrowRight.svg'
import CALENDAR from 'assets/icons/Calendar.svg'
import CAMERA from 'assets/icons/Camera.svg'
import CHEVRON_DOWN from 'assets/icons/ChevronDown.svg'
import CHEVRON_LEFT from 'assets/icons/ChevronLeft.svg'
import CHEVRON_RIGHT from 'assets/icons/ChevronRight.svg'
import CHEVRON_UP from 'assets/icons/ChevronUp.svg'
import CLOCK from 'assets/icons/Clock.svg'
import COMPETITION from 'assets/icons/Competition.svg'
import CROSS from 'assets/icons/Cross.svg'
import DOWNLOAD from 'assets/icons/Download.svg'
import DUPLICATE from 'assets/icons/Duplicate.svg'
import DYNAMIC from 'assets/icons/Dynamic.svg'
import EDIT from 'assets/icons/Edit.svg'
import EXPAND from 'assets/icons/Expand.svg'
import EYE_DEFAULT from 'assets/icons/EyeDefault.svg'
import EYE_OBFUSCATED from 'assets/icons/EyeObfuscated.svg'
import EYE_VIEW from 'assets/icons/EyeView.svg'
import FILTERS from 'assets/icons/Filters.svg'
import GRID from 'assets/icons/Grid.svg'
import GUIDE from 'assets/icons/Guide.svg'
import HAND from 'assets/icons/Hand.svg'
import INFO from 'assets/icons/Info.svg'
import KO from 'assets/icons/KO.svg'
import LIST from 'assets/icons/List.svg'
import LOADING from 'assets/icons/Loading.svg'
import LOCK from 'assets/icons/Lock.svg'
import LOGOUT from 'assets/icons/Logout.svg'
import MAIL from 'assets/icons/Mail.svg'
import MENU from 'assets/icons/Menu.svg'
import MIC from 'assets/icons/Mic.svg'
import MINUS from 'assets/icons/Minus.svg'
import MOUSE from 'assets/icons/Mouse.svg'
import OK from 'assets/icons/OK.svg'
import OPTIONS_HORIZONTAL from 'assets/icons/OptionsHorizontal.svg'
import OPTIONS_VERTICAL from 'assets/icons/OptionsVertical.svg'
import ORDER_DEFAULT from 'assets/icons/OrderDefault.svg'
import ORDER_SELECTED from 'assets/icons/OrderSelected.svg'
import PAUSE from 'assets/icons/Pause.svg'
import PLAY from 'assets/icons/Play.svg'
import PLUS from 'assets/icons/Plus.svg'
import POINTER from 'assets/icons/Pointer.svg'
import RECORDING from 'assets/icons/Recording.svg'
import RELOAD from 'assets/icons/Reload.svg'
import SCORE from 'assets/icons/Score.svg'
import SEARCH from 'assets/icons/Search.svg'
import SECONDS_BACK from 'assets/icons/SecondsBack.svg'
import SECONDS_FORWARD from 'assets/icons/SecondsForward.svg'
import SETTINGS from 'assets/icons/Settings.svg'
import SOUND_LOW from 'assets/icons/SoundLow.svg'
import SOUND_MID from 'assets/icons/SoundMid.svg'
import SOUND_MUTE from 'assets/icons/SoundMute.svg'
import SPEED from 'assets/icons/Speed.svg'
import STAR from 'assets/icons/Star.svg'
import STAR_FILLED from 'assets/icons/StarFilled.svg'
import STOP from 'assets/icons/Stop.svg'
import TRASH from 'assets/icons/Trash.svg'
import UPLOAD from 'assets/icons/Upload.svg'
import USERS from 'assets/icons/Users.svg'

export {
  ADD,
  ALERT,
  AMBIENT,
  ARROW_LEFT,
  ARROW_RIGHT,
  CALENDAR,
  CAMERA,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  CHEVRON_UP,
  CLOCK,
  COMPETITION,
  CROSS,
  DOWNLOAD,
  DUPLICATE,
  DYNAMIC,
  EDIT,
  EXPAND,
  EYE_DEFAULT,
  EYE_OBFUSCATED,
  EYE_VIEW,
  FILTERS,
  GRID,
  GUIDE,
  HAND,
  INFO,
  KO,
  LIST,
  LOADING,
  LOCK,
  LOGOUT,
  MAIL,
  MENU,
  MIC,
  MINUS,
  MOUSE,
  OK,
  OPTIONS_HORIZONTAL,
  OPTIONS_VERTICAL,
  ORDER_DEFAULT,
  ORDER_SELECTED,
  PAUSE,
  PLAY,
  PLUS,
  POINTER,
  RECORDING,
  RELOAD,
  SCORE,
  SEARCH,
  SECONDS_BACK,
  SECONDS_FORWARD,
  SETTINGS,
  SOUND_LOW,
  SOUND_MID,
  SOUND_MUTE,
  SPEED,
  STAR,
  STAR_FILLED,
  STOP,
  TRASH,
  UPLOAD,
  USERS,
}
