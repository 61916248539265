import { useLoading } from 'hooks/useLoading'
import styles from './Loading.module.css'

export function Loading() {
  const { loading, loadingInfo } = useLoading()
  return (
    loading && (
      <div id="loader" style={{ zIndex: 'var(--zIndex-topLayer)' }}>
        <div className={styles.background} />
        <div className={styles.loadingInfo}>
          <div className={styles.loaderBox}>
            <div className={styles.loader} />
            <div style={{ zIndex: 'var(--zIndex-underLayer)' }}>{loadingInfo.header}</div>
          </div>
          <div style={{ maxWidth: loadingInfo.width }}> {loadingInfo.extended} </div>
        </div>
      </div>
    )
  )
}
