import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction, createContext, useState } from 'react'

export type LoadingContextType = {
  loading: boolean
  setLoading(loading: boolean): void
  loadingInfo: LoadingInfo
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfo>>
}

interface LoadingContextProviderProps {
  children: React.ReactNode
}

export const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  setLoading: () => ({}),
  loadingInfo: {
    header: 'Loading...',
  },
  setLoadingInfo: () => ({}),
})

interface LoadingInfo {
  header: string
  extended?: string
  width?: number
}

export function LoadingContextProvider({ children }: LoadingContextProviderProps) {
  const { t } = useTranslation('common')
  const [loading, setLoadingState] = useState(false)
  const [loadingInfo, setLoadingInfo] = useState<LoadingInfo>({
    header: t('loading'),
  })

  const setLoading = (loading: boolean) => {
    setLoadingInfo({ header: t('loading') })
    setLoadingState(loading)
  }

  return (
    <LoadingContext.Provider value={{ loading, setLoading, loadingInfo, setLoadingInfo }}>
      {children}
    </LoadingContext.Provider>
  )
}
