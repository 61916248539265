import { Button, Modal, ModalProps } from 'antd/lib'
import Title from 'antd/lib/typography/Title'
import React, { ReactElement, ReactNode } from 'react'
import { Icon } from '../Icon/Icon'
import { IconNames } from '../Icon/icons'
import styles from './NotificationModal.module.css'
import useTranslation from 'next-translate/useTranslation'

enum IconType {
  OK = 'OK',
  KO = 'KO',
  INFO = 'INFO',
  RECORDING = 'RECORDING',
  DYNAMIC = 'DYNAMIC',
}

interface NotificationModalProps extends ModalProps {
  /**
   * Specifies the notification type
   */
  titleIconType?: IconType

  /**
   * Notification modal bold title
   */
  title?: string | ReactNode

  /**
   * Modal body content
   */
  body?: ReactElement

  /**
   * Custom footer buttons, overrides AntD's
   */
  footerBtns?: ReactElement[]

  /**
   * Add a skip button instead of a close icon,
   */
  skippable?: boolean
}

interface OpenNotificationProps extends NotificationModalProps {
  open?: boolean
}

const DefaultFooterBtns = ({ onOk }: { onOk: (e: React.MouseEvent) => void }) => {
  const { t } = useTranslation('common')
  return (
    <Button key={0} aria-label="ok error" type="ghost" onClick={onOk}>
      {t('ok')}
    </Button>
  )
}

const NotificationModal = ({
  titleIconType = IconType.INFO,
  title = 'Notification',
  body,
  open,
  onOk,
  onCancel,
  cancelText,
  okText,
  footerBtns = [<DefaultFooterBtns key={'default-footer-btns'} onOk={onOk} />],
  skippable = false,
  closable = true,
}: NotificationModalProps) => {
  const { t } = useTranslation('common')
  const getIcon = {
    [IconType.OK]: IconNames.OK,
    [IconType.KO]: IconNames.KO,
    [IconType.INFO]: IconNames.INFO,
    [IconType.RECORDING]: IconNames.RECORDING,
    [IconType.DYNAMIC]: IconNames.DYNAMIC,
  }

  const getIconBox = {
    [IconType.OK]: styles.iconBoxOK,
    [IconType.KO]: styles.iconBoxKO,
    [IconType.INFO]: styles.iconBoxInfo,
    [IconType.RECORDING]: styles.iconBoxKO,
    [IconType.DYNAMIC]: styles.iconBoxInfo,
  }

  const closeIcon = skippable ? (
    <Button type="link">{t('skip')}</Button>
  ) : (
    <Icon component={IconNames.CROSS} size={24} />
  )

  return (
    <Modal
      open={open}
      onOk={onOk}
      width={438}
      centered
      onCancel={onCancel}
      footer={null}
      cancelText={cancelText}
      okText={okText}
      className={styles.modal}
      closeIcon={closeIcon}
      closable={closable}
    >
      <div className={styles.flexcolcenter}>
        <div className={getIconBox[titleIconType]}>
          <Icon component={getIcon[titleIconType]} size={28} />
        </div>
        <Title level={2} className={styles.modalTitle}>
          {title}
        </Title>
        {body}
        <div className={styles.footerBtn}>{footerBtns.map((btn: ReactElement) => btn)}</div>
      </div>
    </Modal>
  )
}

export { NotificationModal, type NotificationModalProps, IconType, type OpenNotificationProps }
